import units from "@/config/units";
import global from "@/config/global";

const INDEX_MENU_ITEM = {
  id: 9,
  key: global.indexMenuItemKey,
  label: "Главная",
  color: "#EBEBEB",
  path: "/",
};

export default [
  INDEX_MENU_ITEM,
  ...Object.values(units).map((unit) => ({
    ...unit,
    path: `/${unit.key}`,
  })),
];
