import isUnitExists from "@/router/middleware/is-unit-exists";

export default [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index"),
  },
  {
    path: "/:unitKey",
    name: "Unit",
    component: () => import("../views/Unit"),
    meta: {
      middleware: isUnitExists,
    },
  },
];
