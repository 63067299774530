export default {
  fms: {
    id: 0,
    key: "fms",
    label: "ФМС",
    color: "#FFCDD2",
  },
  mvd: {
    id: 1,
    key: "mvd",
    label: "ГУВД или МВД",
    color: "#B2EBF2",
  },
  ovd: {
    id: 2,
    key: "ovd",
    label: "УВД или ОВД",
    color: "#C8E6C9",
  },
  police: {
    id: 3,
    key: "police",
    label: "Отделение полиции",
    color: "#FFE0B2",
  },
};
