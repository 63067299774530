<template>
  <section class="menu">
    <div
      v-for="item in menuItemsFormatted"
      :key="item.key"
      :class="[
        'menu__item',
        {
          menu__item_active: item.isActive,
        },
      ]"
      :style="`border-color: ${item.color}`"
      @click="goTo(item.path)"
    >
      <div class="menu__item-label" v-text="item.label" />
      <div v-if="item.amount" class="menu__item-amount" v-text="item.amount" />
    </div>
  </section>
</template>

<script>
import menuItems from "@/config/menuItems";
import global from "@/config/global";
import storeListCommunication from "@/mixins/store-list-communication";

export default {
  name: "Menu",
  mixins: [storeListCommunication],
  computed: {
    activeMenuItemKey() {
      return this.$route.params.unitKey || global.indexMenuItemKey;
    },
    menuItemsFormatted() {
      return menuItems.reduce((acc, item) => {
        const isActive = item.key === this.activeMenuItemKey;
        const amount = this.itemsByUnits[item.key].amount || "";

        if (!amount && !isActive) return acc;

        acc.push({
          ...item,
          isActive,
          amount,
        });
        return acc;
      }, []);
    },
  },
  methods: {
    goTo(path) {
      this.$router.replace({
        path: path,
        query: {
          search: this.$route.query.search || undefined,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  justify-content: space-between;
  gap: $md-gap;
  overflow-x: auto;

  &__item {
    display: flex;
    align-items: center;
    gap: $lg-gap;
    flex: 1;
    padding: $xl-padding;
    white-space: nowrap;
    border-color: none;
    cursor: pointer;

    &-label {
      flex: 1;
    }

    &_active {
      border-bottom: 2px solid;
      padding-bottom: $xl-padding - 2px;
      pointer-events: none;
    }

    &:hover {
      background-color: $menu-item-hovered-background;
    }
  }

  @media (min-width: $lg-breakpoint) {
    flex-direction: column;

    &__item_active {
      border-bottom: unset;
      padding-bottom: $xl-padding;
      border-left: 5px solid;
      padding-left: $xl-padding - 5px;
      pointer-events: none;
    }
  }
}
</style>
