import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/config/routes";
import global from "@/config/global";
import units from "@/config/units";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    to.meta.middleware({ to, next });
  } else next();

  let title = global.defaultTitle;
  if (to.params.unitKey)
    title = `${units[to.params.unitKey].label} - ${global.defaultTitle}`;
  document.title = title;
});

export default router;
