<template>
  <div class="search">
    <div class="search__label">Поиск</div>
    <input
      v-model="searchValue"
      class="search__input"
      placeholder="по названию и коду подразделения"
    />
  </div>
</template>

<script>
import storeListCommunication from "@/mixins/store-list-communication";

export default {
  name: "Search",
  mixins: [storeListCommunication],
  computed: {
    searchValue: {
      get() {
        return this.search || this.$route.query.search;
      },
      set(value) {
        this.setState(["search", value]);
        this.$router.replace({
          query: {
            search: value || undefined,
          },
        });
      },
    },
  },
  watch: {
    searchValue(value) {
      this.getList(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  gap: $lg-gap;

  &__input {
    flex: 1;
    border: 1px solid $search-input-border;
    border-radius: 3px;
    padding: $lg-padding;
  }

  @media (min-width: $lg-breakpoint) {
    width: 100%;
    max-width: $search-max-width;
  }
}
</style>
