<template>
  <section class="logo">
    <img src="@/assets/dadata-logo.svg" class="logo__image" />
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  text-align: center;

  &__image {
    max-width: 150px;
    filter: grayscale(.8);
  }

  @media (min-width: $lg-breakpoint) {
    text-align: unset;
  }
}
</style>
