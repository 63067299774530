import axios from "axios";
import global from "@/config/global";

export const axiosInstance = axios.create({
  baseURL: global.dadataEndpoint,
  headers: {
    Authorization: `Token ${global.dadataToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let cancel = () => {};

export default {
  namespaced: true,
  state: {
    data: [],
    status: "",
    error: null,
    search: "",
  },
  getters: {
    data: (state) => state.data,
    status: (state) => state.status,
    error: (state) => state.error,
    search: (state) => state.search,
  },
  mutations: {
    setState(state, [key, value]) {
      state[key] = value;
    },
  },
  actions: {
    async getList({ commit }, search) {
      cancel();
      await axiosInstance({
        method: "post",
        data: {
          query: search,
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((response) => {
          const formattedList = response.data.suggestions.map(
            (item) => item.data
          );
          commit("setState", ["data", formattedList]);
          commit("setState", ["search", search]);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            commit("setState", ["data", "error"]);
          }
        });
    },
  },
};
