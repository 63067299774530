import { mapGetters, mapMutations, mapActions } from "vuex";
import menuItems from "@/config/menuItems";
import global from "@/config/global";

export default {
  computed: {
    ...mapGetters({
      list: "list/data",
      status: "list/status",
      error: "list/error",
      search: "list/search",
    }),
    itemsByUnits() {
      return menuItems.reduce((acc, menuItem) => {
        if (menuItem.key === global.indexMenuItemKey) {
          acc[menuItem.key] = {
            list: this.list,
            amount: this.list.length
          }
          return acc;
        }

        const items = this.list.filter(
          (listItem) => Number(listItem.type) === menuItem.id
        );
        acc[menuItem.key] = {
          list: items,
          amount: items.length
        }
        return acc;
      }, []);
    },
  },
  methods: {
    ...mapMutations({
      setState: "list/setState",
    }),
    ...mapActions({
      getList: "list/getList",
    }),
  },
};
