<template>
  <div id="app" class="app">
    <div class="app__header">
      <div class="app__header-logo">
        <Logo />
      </div>
      <div class="app__header-search">
        <Search />
      </div>
    </div>
    <div class="app__body">
      <div class="app__body-sidebar">
        <Menu />
      </div>
      <div class="app__body-content">
        <router-view />
      </div>
    </div>
    <div class="app__footer">
      Test task for Frontend developer vacancy
      <p>
        <a href="https://gitlab.com/ivanmenshenin/passports" target="_blank">
          GitLab
        </a>
        |
        <a href="https://t.me/ivanmenshenin_ru" target="_blank">Contact</a>
      </p>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Search from "@/components/Search";
import Menu from "@/components/Menu";

export default {
  name: "App",
  components: {
    Logo,
    Search,
    Menu,
  },
};
</script>

<style lang="scss">
body {
  margin: 0px;
  padding: $md-padding;
  background-color: $app-background;

  @media (min-width: $md-breakpoint) {
    padding: $xl-padding;
  }

  @media (min-width: $lg-breakpoint) {
    padding: $xxl-padding;
  }

  @media (min-width: $xl-breakpoint) {
    padding: $xxl-padding * 2;
  }

  @media (min-width: $xxl-breakpoint) {
    padding: $xxl-padding * 2 $xxl-padding * 4;
  }
}
</style>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  gap: $xxl-gap;
  font-family: $font-family;
  font-size: $md-font;

  &__header,
  &__body {
    display: flex;
    flex-direction: column;
    gap: $xxl-gap;
  }

  &__footer {
    padding: $lg-padding;
    font-size: $sm-font;
    text-align: center;
  }

  @media (min-width: $lg-breakpoint) {
    &__header,
    &__body {
      flex-direction: row;
    }

    &__header-logo,
    &__body-sidebar {
      min-width: $app-sidebar-width;
    }

    &__header-search,
    &__body-content {
      flex: 1;
    }

    &__header-logo,
    &__header-search {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: $xxl-breakpoint) {
    &__header-logo,
    &__body-sidebar {
      min-width: $app-sidebar-xxl-width;
    }
  }
}
</style>
